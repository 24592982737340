// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-brochure-js": () => import("./../../../src/templates/brochure.js" /* webpackChunkName: "component---src-templates-brochure-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-contact-thanks-js": () => import("./../../../src/templates/contact-thanks.js" /* webpackChunkName: "component---src-templates-contact-thanks-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-parts-category-js": () => import("./../../../src/templates/parts-category.js" /* webpackChunkName: "component---src-templates-parts-category-js" */),
  "component---src-templates-parts-js": () => import("./../../../src/templates/parts.js" /* webpackChunkName: "component---src-templates-parts-js" */),
  "component---src-templates-parts-type-js": () => import("./../../../src/templates/parts-type.js" /* webpackChunkName: "component---src-templates-parts-type-js" */)
}

